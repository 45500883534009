export function formatChartData(data) {
  if (data.length === 0) return [];
  let formattedChartData = [];

  data?.chartData.forEach((item) => {
    let dataPoint = {
      label: item.label,
      performance: item.performance,
       color:item.label==="Total"?"#4FD1C5":"#F56565"
    };
    item.personnelPerformances.forEach((personnel) => {
      let stringPersType = data.personnelTypes.find(
        (x) => x._id.toString() === personnel.personnelType.toString()
      );
      if (stringPersType) {
        dataPoint[stringPersType.name] = personnel.personnelPerformance;
      }
    });
    formattedChartData.push(dataPoint);
  });

  //orderby performance but put the ones with label="Total" at the start
  formattedChartData.sort((a, b) => {
    if (a.label === "Total") {
      return -1;
    } else if (b.label === "Total") {
      return 1;
    } else {
      return b.performance - a.performance;
    }
  });

  return formattedChartData;
}
