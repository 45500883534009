import {
  Button,
  ModalContent,
  ModalHeader,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
  Flex,
  Box,
} from "@chakra-ui/react";
import { FiCheck, FiEdit, FiSend, FiUserCheck, FiX } from "react-icons/fi";
import { useParams } from "react-router-dom";
import useSWR, { useSWRConfig } from "swr";
import { useState } from "react";
import PageHeader from "../../../components/Layout/PageHeader";
import PageLayout from "../../../components/Layout/PageLayout";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { useApi } from "../../../contexts/ApiContext";
import { useUiContext } from "../../../contexts/UiContext";
import { useAuth } from "../../../contexts/AuthContext";
import ConfirmAlert from "../../../components/Alert/ConfirmAlert";
import { VisitStatusTag } from "./visitStatusTag";
import {
  VisitImages,
  VisitInfo,
  VisitNotes,
  VisitPerfomance,
  VisitMsInvites,
  VisitSurvey,
  ClientVisitInfo,
} from "./visitDetails";
import { RejectionAlert } from "./visitDetails/rejectionAlert";
import { ScoreAlert } from "./visitDetails/scoreAlert";
import { PlanUpsertContainer } from "./planUpsertContainer";
import Card from "../../../components/Layout/Card";
import { is } from "date-fns/locale";

export function PlanDetailPage() {
  const { isAdmin, isFieldMgr, isLocationMgr, isCompanyMgr } = useAuth();
  const { visitId: id } = useParams();
  const {
    data: visit,
    error: visitError,
    mutate: mutateVisit,
  } = useSWR(`/visits/${id}`);

  const { data: survey, error: surveyError } = useSWR(`/visits/${id}/survey`);

  const { data: projectBreakdowns, error: breakdownsError } = useSWR(
    () => `/projects/${visit.project._id}/project-breakdowns`
  );

  const { data: projectGoals, error: goalsError } = useSWR(
    () => `/projects/${visit.project._id}/goals`
  );

  const toast = useToast();
  const { showModal, closeModal } = useUiContext();

  if (visitError) {
    toast({
      title: "Bir hata oluştu.",
      description: "Lütfen tekrar deneyin.",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  }
  const status = visit?.status;
  const isSurveySubmitedByMs =
    status !== "pending" && status !== "ms_confirmed";

  return (
    <PageLayout big>
      {!visit ? (
        <LoadingIndicator />
      ) : (
        <>
          <PageHeader
            title={
              isAdmin() || isFieldMgr() ? (
                <>
                  {visit?.name} <VisitStatusTag status={status} size={"sm"} />
                </>
              ) : (
                visit?.name
              )
            }
            backUrl={
              isFieldMgr() ? "../project-plan" : isLocationMgr() ? ".." : null
            }
          >
            {(isAdmin() || isFieldMgr()) && status === "pending" && (
              <Button
                size={"sm"}
                colorScheme={"blackAlpha"}
                leftIcon={<FiEdit />}
                onClick={() => {
                  showModal(
                    <ModalContent>
                      <ModalHeader>Ziyaret: {visit.name}</ModalHeader>
                      <PlanUpsertContainer
                        id={id}
                        projectId={visit.project._id}
                        closeModal={closeModal}
                        mutateVisits={() => {}}
                      />
                    </ModalContent>
                  );
                }}
              >
                Düzenle
              </Button>
            )}

            <ActionButtons visit={visit} mutateVisit={mutateVisit} />
          </PageHeader>

          {(isCompanyMgr() || isLocationMgr()) && (
            <Card topMargin>
              <Flex>
                <Box flex="1" mr={4}>
                  <ClientVisitInfo
                    visit={visit}
                    projectBreakdowns={projectBreakdowns}
                    projectGoals={projectGoals}
                    direction="column"
                  />
                </Box>
                <Box flex="1">
                  <VisitPerfomance id={id} />
                </Box>
              </Flex>
              <VisitSurvey survey={survey} visitName={visit?.name} />

            </Card>
          )}

          {(isAdmin() || isFieldMgr()) && (
            <Card topMargin>
              {" "}
              <Tabs mt={8}>
                <TabList>
                  <Tab>Anket Soruları</Tab>
                  {isSurveySubmitedByMs && <Tab>Performans</Tab>}
                  {isSurveySubmitedByMs && <Tab>Ziyaret Resimleri</Tab>}
                  {<Tab>GM Davetleri</Tab>}
                  {<Tab>Notlar</Tab>}
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <VisitInfo
                      visit={visit}
                      projectBreakdowns={projectBreakdowns}
                      projectGoals={projectGoals}
                    />

                    <VisitSurvey survey={survey} visitName={visit?.name} />
                  </TabPanel>

                  {isSurveySubmitedByMs && (
                    <TabPanel>
                      <VisitPerfomance id={id} />
                    </TabPanel>
                  )}
                  {isSurveySubmitedByMs && (
                    <TabPanel>
                      <VisitImages images={visit?.images} />
                    </TabPanel>
                  )}

                  <TabPanel>
                    <VisitMsInvites visit={visit} mutateVisit={mutateVisit} />
                  </TabPanel>

                  <TabPanel>
                    <VisitNotes visitId={id} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Card>
          )}
        </>
      )}
    </PageLayout>
  );
}

function ActionButtons({ visit, mutateVisit }) {
  const { isAdmin, isFieldMgr } = useAuth();
  const { mutate } = useSWRConfig();
  const [isLoading, setIsloading] = useState(false);
  const { showAlert } = useUiContext();
  const { visitApi } = useApi();
  const toast = useToast();

  const { _id: id, status, shopper } = visit;

  async function action(actionType, data) {
    const descriptonObject = {
      ms_confirmed: "Anket GM'ye gönderime hazır.",
      approved: "Anket onaylandı.",
      rejected: "Anket reddedildi.",
      published: "Anket müşteriye gönderime hazır.",
      fm_approved: "Anket onaylandı.",
      fm_rejected: "Anket reddedildi.",
    };

    setIsloading(true);
    try {
      await visitApi.actionExecuter(id, actionType, data);
      mutateVisit();
      if (actionType === "ms_confirmed") {
        mutate(`/visits/${id}/visit-invites`);
      }
      toast({
        title: "Başarılı",
        description: descriptonObject[actionType],
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsloading(false);
  }

  if (!isAdmin() && !isFieldMgr()) return null;

  // admin || field manager - pending
  if (status === "pending") {
    return (
      <Button
        colorScheme="blue"
        size="sm"
        isDisabled={!shopper}
        isLoading={isLoading}
        leftIcon={<FiUserCheck />}
        onClick={() => {
          showAlert(
            <ConfirmAlert
              header="GM Onayla"
              body="Anketi gizli müşteriye göndermek istediğinize emin misiniz?"
              buttonLabel="Onayla"
              buttonColorScheme="blue"
              onAction={() => action("ms_confirmed")}
            />
          );
        }}
      >
        GM Onayla
      </Button>
    );
  }

  // admin
  if (
    isAdmin() &&
    (status === "waiting_approval_admin" ||
      status === "waiting_approval_fm" ||
      status === "fm_approved")
  ) {
    return (
      <>
        <Button
          colorScheme="red"
          size="sm"
          isLoading={isLoading}
          leftIcon={<FiX />}
          onClick={() => {
            showAlert(
              <RejectionAlert
                header="İade Et"
                buttonLabel="İade Et"
                onAction={(rejectionReason) =>
                  action("rejected", rejectionReason)
                }
              />
            );
          }}
        >
          Reddet
        </Button>
        <Button
          colorScheme="green"
          size="sm"
          isLoading={isLoading}
          leftIcon={<FiCheck />}
          onClick={() => {
            showAlert(
              <ScoreAlert
                header="Anket Onayla"
                buttonLabel="Onayla"
                onAction={(scoreData) => action("approved", scoreData)}
              />
            );
          }}
        >
          Onayla
        </Button>
      </>
    );
  }
  if (isAdmin() && status === "approved") {
    return (
      <Button
        colorScheme="pink"
        size="sm"
        isLoading={isLoading}
        leftIcon={<FiSend />}
        onClick={() => {
          showAlert(
            <ConfirmAlert
              header="Anket Yayınla"
              body="Bu anketi müşteriye yayınlamak istediğinize emin misiniz?"
              buttonLabel="Yayınla"
              buttonColorScheme="pink"
              onAction={() => action("published")}
            />
          );
        }}
      >
        Yayınla
      </Button>
    );
  }

  // field manager
  if (isFieldMgr() && status === "waiting_approval_fm") {
    return (
      <>
        <Button
          colorScheme="red"
          size="sm"
          isLoading={isLoading}
          leftIcon={<FiX />}
          onClick={() => {
            showAlert(
              <RejectionAlert
                header="Anketi Reddet"
                buttonLabel="Reddet"
                onAction={(rejectionReason) =>
                  action("fm_rejected", rejectionReason)
                }
              />
            );
          }}
        >
          Reddet
        </Button>
        <Button
          colorScheme="green"
          size="sm"
          isLoading={isLoading}
          leftIcon={<FiCheck />}
          onClick={() => {
            showAlert(
              <ConfirmAlert
                header="Anket Onayla"
                body="Bu anketi onaylamak istediğinize emin misiniz?"
                buttonLabel="Onayla"
                buttonColorScheme="green"
                onAction={() => action("fm_approved")}
              />
            );
          }}
        >
          Onayla
        </Button>
      </>
    );
  }

  return null;
}
