import { Box, Image, Text, Collapse } from "@chakra-ui/react";
import useSWR from "swr";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Icon,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import Card from "../../components/Layout/Card";
import { Fragment, useState } from "react";

export function VisitPerformanceTable({ visitId }) {
  const [expandedCategories, setExpandedCategories] = useState({});

  const { data } = useSWR(`/getVisitReport/${visitId}`);
  const toggleCategory = (categoryId) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [categoryId]: !prev[categoryId],
    }));
  };

  return (
    <>
      {data && (
        <TableContainer>
          <Table variant="simple" size="lg" borderRadius="md" overflow="hidden">
            {" "}
            <Thead>
              <Tr>
                <Th rowSpan={2}>
                  <Image src="/beymen.png" height={33} />
                </Th>
                <Th>Ziyaret Puanı</Th>
                <Th>Lokasyon Ortalaması</Th>
                <Th>Proje Ortalaması</Th>
              </Tr>
              <Tr>
                <Th fontSize="2xl">
                  %{data.visitPerformance.performance}
                  {/*data.visitPerformance.totalScore} /{" "}
                  {data.visitPerformance.totalMaxScore}*/}
                </Th>
                <Th fontSize="2xl">
                  %{data.locationPerformance.performance}
                  {/*data.locationPerformance.totalScore} /{" "}
                  {data.locationPerformance.totalMaxScore}*/}
                </Th>
                <Th fontSize="2xl">
                  %{data.projectPerformance.performance}
                  {/*data.projectPerformance.totalScore} /{" "}
                  {data.projectPerformance.totalMaxScore}*/}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.mainCategoryList.map((mainCat) => {
                const visitCategory = data.visitPerformance.categoryScores.find(
                  (x) => x.category === mainCat._id
                );
                const locationCategory =
                  data.locationPerformance.categoryScores.find(
                    (x) => x.category === mainCat._id
                  );
                const projectCategory =
                  data.projectPerformance.categoryScores.find(
                    (x) => x.category === mainCat._id
                  );
                return (
                  <Fragment key={mainCat._id}>
                    <Tr
                      bgColor={"gray.100"}
                      fontWeight={900}
                      color={"gray.900"}
                    >
                      <Td
                        width="25%"
                        onClick={() => toggleCategory(mainCat._id)}
                        style={{ cursor: "pointer" }}
                      >
                        {mainCat.subCategories.length > 0 && (
                          <Icon
                            as={
                              expandedCategories[mainCat._id]
                                ? ChevronDownIcon
                                : ChevronRightIcon
                            }
                          />
                        )}
                        {mainCat.name}
                      </Td>
                      <Td width="25%">{reportTableCell(visitCategory)}</Td>
                      <Td width="25%">{reportTableCell(locationCategory)}</Td>
                      <Td width="25%">{reportTableCell(projectCategory)}</Td>
                    </Tr>
                    <Tr>
                      <Td colSpan={4} p={0}>
                        <Collapse
                          in={expandedCategories[mainCat._id]}
                          animateOpacity
                        >
                          <Table>
                            <Tbody>
                              {mainCat.subCategories.length > 0 &&
                                mainCat.subCategories.map((subCat) => {
                                  const visitSubCategory =
                                    data.visitPerformance.categoryScores.find(
                                      (x) => x.category === subCat._id
                                    );
                                  const locationSubCategory =
                                    data.locationPerformance.categoryScores.find(
                                      (x) => x.category === subCat._id
                                    );
                                  const projectSubCategory =
                                    data.projectPerformance.categoryScores.find(
                                      (x) => x.category === subCat._id
                                    );
                                  return (
                                    <Tr key={subCat._id}>
                                      <Td width="25%">{subCat.name}</Td>
                                      <Td width="25%">
                                        {reportTableCell(visitSubCategory)}
                                      </Td>
                                      <Td width="25%">
                                        {reportTableCell(locationSubCategory)}
                                      </Td>
                                      <Td width="25%">
                                        {reportTableCell(projectSubCategory)}
                                      </Td>
                                    </Tr>
                                  );
                                })}
                            </Tbody>
                          </Table>
                        </Collapse>
                      </Td>
                    </Tr>
                  </Fragment>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      <Box mt={4}>
        <Card>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Personel</Th>
                  <Th>Performans</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data &&
                  data.personnelPerformanceData?.map((pRow) => (
                    <Tr key={pRow.personnelType}>
                      <Td>
                        {pRow.personnelType}
                        <br />
                        {pRow.personnelName}
                      </Td>
                      <Td>
                        <>
                          <Text fontSize="xl"> {"%" + pRow.performance} </Text>
                          {/*<Text fontSize="xs">
                            {pRow.totalPersonnelScore +
                              " / " +
                              pRow.totalMaxPersonnelScore}
                          </Text>}*/}
                        </>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    </>
  );
}
// <Text fontSize="xs">{data.maxScore !=0 && data.score + " / " + data.maxScore}</Text>
const reportTableCell = (data) => {
  return (
    <>
      <Text> {data.maxScore === 0 ? "_" : "%" + data.performance} </Text>
    </>
  );
};
