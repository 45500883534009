import { useMemo } from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { Box, SimpleGrid, Text, Heading, Badge, Flex } from "@chakra-ui/react";
import PageHeader from "../../components/Layout/PageHeader";
import PageLayout from "../../components/Layout/PageLayout";
import LoadingIndicator from "../../components/LoadingIndicator";
import Card from "../../components/Layout/Card";
import { ProjectStatusTag } from "../pms/project/projectStatusTag";

export function ClientProjectListPage() {
  const { data: projects, error } = useSWR("/projects");

  const ProjectCard = ({ project }) => (
    <Card>
      <Box p={5}>
        <Flex justifyContent="space-between" alignItems="center" mb={3}>
          <Heading as="h3" size="md">
            <Link to={`project/${project._id}/dashboard`}>
              {project.name}
            </Link>
          </Heading>
          <ProjectStatusTag status={project.status} size="md" />
        </Flex>
        <Text mb={4} color="gray.600">
          {project.description || "Proje açıklaması bulunmuyor."}
        </Text>
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize="sm" color="gray.500">
            Başlangıç: {new Date(project.startDate).toLocaleDateString()}
          </Text>
          <Text fontSize="sm" color="gray.500">
            Bitiş: {new Date(project.endDate).toLocaleDateString()}
          </Text>
        </Flex>
      </Box>
    </Card>
  );

  if (error) return <div>Projeler yüklenirken bir hata oluştu.</div>;
  if (!projects) return <LoadingIndicator />;

  return (
    <PageLayout>
      <PageHeader title="Proje Listesi" />
      <Box mt={4}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
          {projects.map((project) => (
            <ProjectCard key={project._id} project={project} />
          ))}
        </SimpleGrid>
      </Box>
    </PageLayout>
  );
}
