import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  VStack,
} from "@chakra-ui/react";
import { Controller, useWatch } from "react-hook-form";
import { ChoiceStack } from "../../../components/Form";
import { QuestionBox } from "./questionBox";
import { ExtraNote } from "./extraNote";
import { regexNoEmptyString } from "../../../util/regex";

export function SingleSelectQuestion({
  question,
  index,
  errors,
  control,
  skipQuestion,
  register,
}) {
  const { _id: questionId, questionText, extraNote, choices } = question;
  const name = `${questionId}.selectedChoice`;
  const error = errors[questionId]?.selectedChoice;

  const hasChoiceDescriptionErrors = errors[questionId]?.choiceDescriptions
    ? Object.keys(errors[questionId]?.choiceDescriptions).length > 0
    : false;

  return (
    <FormControl
      as="fieldset"
      id={name}
      isInvalid={error}
      isDisabled={skipQuestion}
    >
      <FormLabel as="legend">{`${question.order}. Soru${
        skipQuestion ? " (Bu soruyu atlayabilirsiniz.)" : ""
      }`}</FormLabel>
      <QuestionBox questionText={questionText} skipQuestion={skipQuestion} />
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <RadioGroup {...field}>
            <ChoiceStack error={error || hasChoiceDescriptionErrors}>
              {choices.map((choice) => {
                const { _id: choiceId, choiceText } = choice;
                const choiceDescriptionError =
                  errors[questionId]?.choiceDescriptions?.[choiceId];

                return (
                  <VStack key={choiceId} align={"flex-start"} spacing={1}>
                    <Radio value={choiceId}>{choiceText}</Radio>

                    {choice.descriptionText && (
                      <ChoiceDescriptionInput
                        questionId={questionId}
                        choiceId={choiceId}
                        descriptionText={choice.descriptionText}
                        choiceDescriptionError={choiceDescriptionError}
                        skipQuestion={skipQuestion}
                        register={register}
                        control={control}
                      />
                    )}
                  </VStack>
                );
              })}
            </ChoiceStack>
          </RadioGroup>
        )}
        rules={{
          validate: (value) =>
            skipQuestion ? true : value ? true : "Gerekli alan",
        }}
      />

      {extraNote && <ExtraNote extraNote={extraNote} />}

      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}

function ChoiceDescriptionInput({
  questionId,
  choiceId,
  descriptionText,
  choiceDescriptionError,
  skipQuestion,
  register,
  control,
}) {
  const questionWatch = useWatch({
    control,
    name: questionId,
  });
  const selectedChoice = questionWatch?.selectedChoice;

  const isDisabled =
    skipQuestion ||
    !selectedChoice ||
    (selectedChoice && selectedChoice !== choiceId);

  return (
    <FormControl isInvalid={choiceDescriptionError} isDisabled={isDisabled}>
      <Input
        size={"sm"}
        variant={"filled"}
        errorBorderColor="#E53E3E"
        borderRadius={"md"}
        placeholder={descriptionText}
        _placeholder={{ opacity: 1, color: "gray.700" }}
        {...register(`${questionId}.choiceDescriptions.${choiceId}`, {
          validate: (value) => {
            if (selectedChoice && selectedChoice === choiceId) {
              return !value // if value is undefined
                ? "Gerekli alan"
                : value.match(regexNoEmptyString) // check for empty string
                ? true
                : "Gerekli alan";
            } else {
              return true; // if choice is not selected
            }
          },
        })}
      />
      <FormErrorMessage>{choiceDescriptionError?.message}</FormErrorMessage>
    </FormControl>
  );
}
