import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Box,
  Text,
  Badge,
  Tag,
  useToast,
  Select,
} from "@chakra-ui/react";
import { FiPlus, FiMoreHorizontal } from "react-icons/fi";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import PageHeader from "../../../components/Layout/PageHeader";
import PageLayout from "../../../components/Layout/PageLayout";
import ClientTable from "../../../components/Table/ClientTable";
import LoadingIndicator from "../../../components/LoadingIndicator";
import DeleteConfirmAlert from "../../../components/Alert/DeleteConfirmAlert";
import { useAuth } from "../../../contexts/AuthContext";
import { useApi } from "../../../contexts/ApiContext";
import { useUiContext } from "../../../contexts/UiContext";
import { getRoleLabel } from "../../../util/stringUtils";
import Card from "../../../components/Layout/Card";

export function UserIndexPage() {
  const navigate = useNavigate();
  const toast = useToast();
  const { userApi } = useApi();
  const { showAlert, closeAlertBox } = useUiContext();
  const { data, error, mutate } = useSWR("/users");
  const { getCurrentUserId } = useAuth();
  const [selectedRole, setSelectedRole] = useState("all");

  const users = data
    ?.map((user) => {
      if (user.company) return user;
      if (user.role === "admin") {
        return {
          ...user,
          company: {
            name: user.adminOf === "cizgi" ? "Çizgi Araştırma" : "Digital 98",
          },
        };
      }
      if (user.role === "fieldMgr") {
        return { ...user, company: { name: "Çizgi Araştırma" } };
      }
      return { ...user, company: { name: "" } };
    })
    .filter((u) => u._id !== getCurrentUserId())
    .filter((u) => selectedRole === "all" || u.role === selectedRole);

  const columns = useMemo(
    () => [
      {
        Header: "AD SOYAD",
        accessor: "name",
        Cell: ({ cell }) => {
          const { name, role, email } = cell.row.original;
          // console.log(cell.row.origin)
          return (
            <Box>
              <Text fontWeight="bold">
                {name}
                {role === "admin" && (
                  <Tag ml="1" colorScheme="pink">
                    A
                  </Tag>
                )}
              </Text>
              <Text fontSize="sm">{email}</Text>
            </Box>
          );
        },
      },
      {
        Header: "ŞİRKET",
        accessor: "company.name",
      },
      {
        Header: "TELEFON",
        accessor: "phone",
      },
      {
        Header: "ROL",
        accessor: "role",
        Cell: ({ cell }) => <RoleTag role={cell.row.values.role} />,
      },
      {
        Header: "DURUM",
        accessor: "status",
        Cell: ({ cell }) => <StatusBadge status={cell.row.values.status} />,
      },
      {
        Header: "",
        accessor: "_id",
        Cell: ({ cell }) => (
          <Menu>
            <MenuButton
              variant="ghost"
              size="sm"
              as={IconButton}
              icon={<FiMoreHorizontal />}
            />
            <MenuList>
              <MenuItem
                onClick={() => {
                  const { role, _id } = cell.row.values;
                  const url =
                    role === "ms"
                      ? `/ina/msm/mystery-shoppers/${_id}`
                      : `${_id}`;
                  navigate(url);
                }}
              >
                Düzenle
              </MenuItem>
              <MenuItem
                onClick={() => {
                  if (cell.row.values.status === "active") {
                    updateStatus(cell.row.values._id, "inactive");
                  } else {
                    updateStatus(cell.row.values._id, "active");
                  }
                }}
              >
                {cell.row.values.status === "active"
                  ? "Pasife al"
                  : "Aktive et"}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  showAlert(
                    <DeleteConfirmAlert
                      name={"Kullanıcı"}
                      onDelete={() => deleteRow(cell.row.values._id)}
                    />
                  );
                }}
              >
                Sil
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      },
    ],
    []
    // [closeAlertBox, deleteRow, navigate, showAlert, updateStatus]
  );

  async function deleteRow(id) {
    try {
      await userApi.deleteOne(id);
      mutate();
      toast({
        title: "Başarılı",
        description: "Kullanıcı silindi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  async function updateStatus(id, status) {
    try {
      await userApi.updateOne(id, { status });
      mutate();
      toast({
        title: "Başarılı",
        description: "Kullanıcı durumu değişti.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  if (error) {
    toast({
      title: "Bir hata oluştu.",
      status: "error",
    });
  }

  return (
    <PageLayout>
      <PageHeader title="Kullanıcılar">
        <Button
          leftIcon={<FiPlus />}
          size="sm"
          colorScheme="pink"
          onClick={() => navigate("new")}
        >
          Yeni Ekle
        </Button>
      </PageHeader>
      <Card topMargin>
        <Box mb={4}>
          <Select
            value={selectedRole}
            onChange={(e) => setSelectedRole(e.target.value)}
            width="200px"
          >
            <option value="all">Tüm Roller</option>
            <option value="admin">Admin</option>
            <option value="ms">Gizli Müşteri</option>
            <option value="companyMgr">Şirket Müdürü</option>
            <option value="fieldMgr">Saha Çalışanı</option>
          </Select>
        </Box>
        {!users ? (
          <LoadingIndicator />
        ) : (
          <ClientTable
            data={users}
            columns={columns}
            tableName="settings-users"
          />
        )}
      </Card>
    </PageLayout>
  );
}

function RoleTag({ role }) {
  const colors = {
    admin: "pink",
    fieldMgr: "purple",
    ms: "blue",
    companyMgr: "teal",
    locationMgr: "green",
  };
  const color = colors[role] ?? "gray";

  return <Tag colorScheme={color}>{getRoleLabel(role)}</Tag>;
}

function StatusBadge({ status }) {
  if (status === "active") {
    return (
      <Badge variant="solid" colorScheme="green">
        aktif
      </Badge>
    );
  }
  return <Badge variant="solid">pasif</Badge>;
}
