import { Route } from "react-router-dom";
import {
  ProjectDashboardPage,
  ProjectDetailsPage,
  ProjectIndexPage,
  ProjectResultPage,
  ProjectUpsertContainer,
} from "../pages/pms/project";
import { PlanIndexPage, PlanDetailPage } from "../pages/pms/plan";
import { FormBuilderPage } from "../pages/pms/form/formBuilderPage";
import { PersonnelTypesIndexPage } from "../pages/pms/personnel-types/personnelTypesIndexPage";
import { GoalsIndexPage } from "../pages/pms/goals/goalsIndexPage";
import { ProjectBreakDownsIndexPage } from "../pages/pms/project-breakdowns/projectBreakdownsIndexPage";
import { VisitScenariosIndexPage } from "../pages/pms/visit-scenarios/visitScenariosIndexPage";
import { MSSurveyContainer } from "../pages/accMs/msSurvey/msSurveyContainer";

export const pmsRoutes = (
  <Route path="pms/projects">
    <Route index element={<ProjectIndexPage />} />
    <Route path="archive" element={<ProjectIndexPage archive />} />
    <Route path="new" element={<ProjectUpsertContainer />} />
    <Route path=":id/edit" element={<ProjectUpsertContainer />} />
    <Route path="survey/:id" element={<MSSurveyContainer />} />
    <Route path=":id" element={<ProjectDetailsPage />}>
      <Route path="project-dashboard" element={<ProjectDashboardPage />} />
      <Route path="project-plan" element={<PlanIndexPage />} />
      <Route path="project-plan/:visitId" element={<PlanDetailPage />} />      
      <Route path="formbuilder" element={<FormBuilderPage />} />
      <Route path="personnel-types" element={<PersonnelTypesIndexPage />} />
      <Route path="goals" element={<GoalsIndexPage />} />
      <Route path="project-result" element={<ProjectResultPage />} />
      <Route path="project-reports" element={<div>Proje raporları</div>} />
      <Route path="project-finance" element={<div>Proje fınansalları</div>} />
      <Route
        path="project-breakdowns"
        element={<ProjectBreakDownsIndexPage />}
      />
      <Route path="visit-scenarios" element={<VisitScenariosIndexPage />} />
    </Route>
  </Route>
);
