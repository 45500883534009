import { Box, Divider, Flex, Icon, Stack, IconButton, Tooltip, useMediaQuery } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

// linkItems = [ { name, icon, to, quantity }, ...]
export default function SplitPageWithSideNav({
  linkItems,
  children,
  firstItemAlwaysBold,
}) {
  const [isOpen, setIsOpen] = useState(true);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    if (!isLargerThan768) {
      setIsOpen(false);
    }
  }, [isLargerThan768]);

  return (
    <Flex>
      <Box
        position="fixed"
        left={4}
        top="70px"
        bottom={4}
        overflowY="auto"
        pb={2}
        px={4}
        borderRadius="lg"
        borderWidth="1px"
        borderColor="gray.200"
        width={isOpen ? "260px" : "60px"}
        transition="all 0.3s"
        zIndex={2}
        bg="rgba(255, 255, 255, 0.8)"
        backdropFilter="blur(10px)"
        boxShadow="lg"
        display="flex"
        flexDirection="column"
      >
        <Box flex="1" overflowY="auto">
          {linkItems.map((link, index) => (
            <div key={link.name}>
              {isOpen ? (
                <NavItem
                  icon={link.icon}
                  to={link.to}
                  firstItemAlwaysBold={index === 0 && firstItemAlwaysBold}
                >
                  {link.name}
                  {link.quantity && ` (${link.quantity})`}
                </NavItem>
              ) : (
                <Tooltip label={link.name} placement="right">
                  <Box>
                    <NavItem
                      icon={link.icon}
                      to={link.to}
                      firstItemAlwaysBold={index === 0 && firstItemAlwaysBold}
                    />
                  </Box>
                </Tooltip>
              )}
              {link.divider && <Divider borderColor="gray.300" />}
            </div>
          ))}
        </Box>
        <IconButton
          icon={isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          onClick={() => setIsOpen(!isOpen)}
          alignSelf="flex-end"
          mt={2}
          size="sm"
          borderRadius="full"
        />
      </Box>
      <Box ml={isOpen ? "280px" : "80px"} w="full" transition="all 0.3s" pt="70px">
        {children}
      </Box>
    </Flex>
  );
}

const NavItem = ({ to, icon, children, firstItemAlwaysBold, ...rest }) => {
  return (
    <NavLink to={to}>
      {({ isActive }) => (
        <Flex
          bg={isActive && "gray.700"}
          color={isActive && "white"}
          fontWeight={isActive || firstItemAlwaysBold ? "bold" : "normal"}
          align="center"
          px="1"
          py="2"
          my={{ base: 1, md: 2 }}
          borderRadius="12"
          role="group"
          cursor="pointer"
          _hover={{
            bg: "gray.50",
            color: "gray.600",
          }}
          _active={{
            bg: "gray.100",
            color: "gray.600",
          }}
          {...rest}
        >
          {icon && (
            <Icon
              mr={children ? "3" : "0"}
              fontSize="18"
              _groupHover={{
                color: "gray.600",
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      )}
    </NavLink>
  );
};
