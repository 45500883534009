import { Box, FormControl, FormErrorMessage,FormLabel } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import PickDate from "../../../components/DatePicker/PickDate";
import Card from "../../../components/Layout/Card";
export function SurveyDate({ control, errors, ...props }) {
  return (
    <Card topMargin>
      <Box
        borderColor={errors?.visitDate && "red"}
        borderWidth={errors?.visitDate && 2}
        borderRadius={"md"}
        {...props}
      >
        <FormControl isInvalid={errors?.visitDate} width={500} >
          <FormLabel htmlFor={`visitDate`}>
          Ziyaret Tarihi
          </FormLabel>
          <Controller
            control={control}
            name="visitDate"
            render={({ field }) => (
              <PickDate maxDate={new Date()} showTimeSelect={true} field={field} placeholder={"Ziyaret Tarihi"} />
            )}
            rules={{ required: "Gerekli Alan" }}
          />
          <FormErrorMessage pl={4} mb={2}>
            {errors?.visitDate?.message}
          </FormErrorMessage>
        </FormControl>
      </Box>
    </Card>
  );
}
