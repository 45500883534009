import { useParams } from "react-router-dom";
import useSWR from "swr";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { MSSurvey } from "./msSurvey";

export function MSSurveyContainer() {
  const { id: visitId } = useParams();
  console.log("visitId:", visitId);
  const { data: survey, mutate } = useSWR(`/visits/${visitId}/survey`);
  const questionForm = survey?.questionForm;

  // Sorulara order ekle
  let order = 1;
  function assignOrderToQuestions(questions) {
    questions.forEach((question) => {
      question.order = order++;
    });
  }
  questionForm?.forEach((item) => {
    // Ana kategori soruları için order ekle
    assignOrderToQuestions(item.questions);

    // Alt kategorileri kontrol et
    if (item.subCategories) {
      item.subCategories.forEach((subCategory) => {
        // Alt kategori soruları için order ekle
        assignOrderToQuestions(subCategory.questions);
      });
    }
  });
  //End: Sorulara order

  // convert array of answers into an object with questionIds as keys
  const answers = convertAnswersArrayIntoAnObject(survey);

  // convert array of personnelNames into an object with personnelTypeIds as keys
  const personnelNames = survey?.personnelNames.reduce(
    (acc, cur) => ({ [cur.personnelType]: cur.name, ...acc }),
    {}
  );
  // create a separate array of personnelType labels with personnelTypeIds as keys (instead of making another network request for project personnel types)
  const personnelTypeLabels = survey?.personnelNames.reduce(
    (acc, cur) => ({ [cur.personnelType]: cur.label, ...acc }),
    {}
  );

  const visitDate = survey?.visitDate;

  if (!survey) {
    return <LoadingIndicator />;
  }

  return (
    <MSSurvey
      visitId={visitId}
      questionForm={questionForm}
      answers={answers}
      personnelData={{ personnelNames, personnelTypeLabels }}
      visitDate={visitDate}
      mutateQuestionForm={mutate}
    />
  );
}

export function convertAnswersArrayIntoAnObject(survey) {
  return survey?.answers.reduce((acc, answer) => {
    const { question, ...answerWithoutQuestion } = answer;
    // convert answer's choiceDescriptions array into an object with choiceIds as keys
    if (answerWithoutQuestion.choiceDescriptions?.length) {
      answerWithoutQuestion.choiceDescriptions =
        answerWithoutQuestion.choiceDescriptions.reduce((acc, descObj) => {
          const { choice, description } = descObj;
          return {
            [choice]: description,
            ...acc,
          };
        }, {});
    } else {
      answerWithoutQuestion.choiceDescriptions = undefined; // if no choice description
    }
    // ------------
    // convert array of answers into an object with questionIds as keys
    return {
      [question]: { ...answerWithoutQuestion },
      ...acc,
    };
  }, {});
}
