import {
  Button,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogBody,
  FormControl,
  Input,
  FormErrorMessage,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useApi } from "../../../contexts/ApiContext";
import StandardFormControl from "../../../components/Form/StandardFormControl";
import { HexColorPicker } from "react-colorful";

export default function PersonnelTypeUpsertAlert({
  personnelType = {},
  projectId,
  onClose,
  mutatePersonnelTypes,
}) {
  const toast = useToast();
  const { personnelTypeApi } = useApi();

  const { id, name, color, order } = personnelType;
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      name: name ?? "",
      color: color ?? "#000000",
      order: order ?? 0,
    },
  });

  const watchColor = watch("color");

  const onSubmit = async (data) => {
    console.log(data);
    let description = "";
    try {
      if (id) {
        await personnelTypeApi.updateOne(id, data);
        description = "Personel tipi başarıyla düzenlendi.";
      } else {
        await personnelTypeApi.createOneForProject(projectId, data);
        description = "Yeni personel tipi başarıyla eklendi.";
      }
      toast({
        title: "Başarılı",
        description,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      mutatePersonnelTypes();
      onClose();
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <AlertDialogContent>
      <AlertDialogHeader fontSize="lg" fontWeight="bold">
        {id ? "Personel Düzenle" : "Yeni Personel Ekle"}
      </AlertDialogHeader>

      <form onSubmit={handleSubmit(onSubmit)}>
        <AlertDialogBody>
          <VStack spacing={4}>
            <StandardFormControl errors={errors}>
              {/* <FormLabel htmlFor="name">Personel Tipi</FormLabel> */}
              <Input
                id="name"
                placeholder="Personel Tipi"
                {...register("name", {
                  required: "Gerekli alan",
                  minLength: {
                    value: 3,
                    message: "En az 3 karakter gerekli.",
                  },
                })}
              />
              <StandardFormControl errors={errors} name="order" label="Sıra">
                <Input
                  id="order"
                  placeholder="Sıra"
                  type="number"
                  {...register("order", {
                    required: "Gerekli alan",
                    min: {
                      value: 1,
                      message: "En az 1 olmalı.",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.order && errors.order.message}
                </FormErrorMessage>
              </StandardFormControl>

              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </StandardFormControl>
            <StandardFormControl errors={errors} name="color" label="Renk Seçimi">
              <HexColorPicker
                color={watchColor}
                onChange={(color) => setValue("color", color)}
              />
              <Input
                id="color"
                value={watchColor}
                onChange={(e) => setValue("color", e.target.value)}
                placeholder="Renk Kodu"
              />
              <FormErrorMessage>
                {errors.color && errors.color.message}
              </FormErrorMessage>
            </StandardFormControl>
          </VStack>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button onClick={onClose}>Vazgeç</Button>
          <Button
            colorScheme="pink"
            ml={3}
            isLoading={isSubmitting}
            type="submit"
          >
            Kaydet
          </Button>
        </AlertDialogFooter>
      </form>
    </AlertDialogContent>
  );
}
