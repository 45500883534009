import { useParams } from "react-router-dom";
import useSWR from "swr";
import PageHeader from "../../components/Layout/PageHeader";
import PageLayout from "../../components/Layout/PageLayout";
import Card from "../../components/Layout/Card";
import { VisitTable } from "../reporting/visitTable";
import Select from "react-select";
import { useState } from "react";

export function ClientVisitListPage() {
  const [location, setLocation] = useState(false);

  const { projectId } = useParams();
  //Get Visit
  const { data: visits } = useSWR(`/projects/${projectId}/visits`);
  const { data: project } = useSWR(`/projects/${projectId}`);
  const { data: locations } = useSWR(
    `/companies/${project?.company._id}/locations`
  );
  const locationOptions = locations?.map((location) => ({
    value: location._id,
    label: location.name,
  }));
  const onSelectLocation = (event) => {
    setLocation(event.value);
  };
  return (
    <PageLayout big>
      <Card mt={12}>
        {/* <Select
          placeholder={"Lokasyon"}
          options={locationOptions}
          onChange={onSelectLocation}
        /> */}
        <PageHeader inBox title="Ziyaret Listesi" />
        <VisitTable visits={visits} />
      </Card>
    </PageLayout>
  );
}
