import { Box, Text } from "@chakra-ui/react";

export default function ItemDetail({ label, value, medium, ...props }) {
  return (
    <Box  {...props}>
      <Text fontSize={medium ? "sm" : "xs"} fontWeight={"bold"}>
        {label}
      </Text>
      <Text fontSize={medium ? "md" : "sm"}>{value}</Text>
    </Box>
  );
}
