import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Textarea,
} from "@chakra-ui/react";
import { QuestionBox } from "./questionBox";
import { ExtraNote } from "./extraNote";
import { regexNoEmptyString } from "../../../util/regex";

export function FreeTextQuestion({
  question,
  index,
  register,
  errors,
  skipQuestion,
}) {
  const { _id, questionText, extraNote } = question;
  const name = `${_id}.freeTextAnswer`;
  const error = errors[_id]?.freeTextAnswer;

  return (
    <FormControl isInvalid={error} isDisabled={skipQuestion}>
      <FormLabel htmlFor={name}>{`${question.order}. Soru${
        skipQuestion ? " (Bu soruyu atlayabilirsiniz.)" : ""
      }`}</FormLabel>
      <QuestionBox questionText={questionText} skipQuestion={skipQuestion} />
      <Textarea
        variant={"filled"}
        id={name}
        {...register(name, {
          validate: (value) =>
            skipQuestion
              ? true
              : value.match(regexNoEmptyString)
              ? true
              : "Gerekli alan",
        })}
      />

      {extraNote && <ExtraNote extraNote={extraNote} />}

      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}
