import React, { useState, useRef } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import useSWR from "swr";
import { formatChartData } from "../../util/reporting";
import Card from "../Layout/Card";
import PageHeader from "../Layout/PageHeader";
import { VisitTable } from "../../pages/reporting/visitTable";
import { Box, Button, Flex, Menu, MenuButton, MenuList, MenuItem, IconButton, useToast } from "@chakra-ui/react";
import { ChevronDownIcon, DownloadIcon } from "@chakra-ui/icons";
import html2canvas from 'html2canvas';

export default function ReportChart({
  category,
  breakdown,
  baseBreakdownId,
  reportType,
  projectId,
  location,
  dashboard,
}) {
  const [sortType, setSortType] = useState("performance");
  const [sortOrder, setSortOrder] = useState("desc");
  const chartRef = useRef(null);
  const personnelChartRef = useRef(null);
  const toast = useToast();

  let queryParam = "";
  if (reportType === "breakdown-based") {
    queryParam += `baseBreakdownId=${baseBreakdownId}&`;
  }

  if (category) {
    queryParam = queryParam + `category=${category}&`;
  }

  if (location) {
    queryParam = queryParam + `location=${location}&`;
  }

  //ADD BREAKDOWN SELECTIONS
  const breakDownLength = Object.keys(breakdown).length;
  if (breakDownLength > 0) {
    let breakdownQuery = "breakdowns=";
    Object.values(breakdown).forEach((value, index) => {
      if (index + 1 < breakDownLength) {
        breakdownQuery += value + "-";
      } else {
        breakdownQuery += value;
      }
    });
    queryParam = queryParam + breakdownQuery;
  }

  const { data, error } = useSWR(`/${reportType}/${projectId}?${queryParam}`);
  const formattedChartData = data && formatChartData(data);
  console.log(data);
  if (!data) return <h1>Loading...</h1>;

  const sortPerformanceData = (data) => {
    const totalData = data.find(item => item.label === "Total");
    const sortableData = data.filter(item => item.label !== "Total");

    sortableData.sort((a, b) => {
      if (sortType === "performance") {
        return sortOrder === "desc"
          ? b.performance - a.performance
          : a.performance - b.performance;
      } else { // name
        return sortOrder === "desc"
          ? b.label.localeCompare(a.label)
          : a.label.localeCompare(b.label);
      }
    });

    return totalData ? [totalData, ...sortableData] : sortableData;
  };

  const sortPersonnelData = (data) => {
    return data.sort((a, b) => {
      if (sortType === "performance") {
        const aValue = Object.values(a)[1]; // İlk sütun isim, ikinci sütun performans değeri
        const bValue = Object.values(b)[1];
        return sortOrder === "desc" ? bValue - aValue : aValue - bValue;
      } else { // name
        return sortOrder === "desc"
          ? b.label.localeCompare(a.label)
          : a.label.localeCompare(b.label);
      }
    });
  };

  const handleSortChange = (newSortType) => {
    if (newSortType === sortType) {
      setSortOrder(prevOrder => prevOrder === "desc" ? "asc" : "desc");
    } else {
      setSortType(newSortType);
      setSortOrder("desc");
    }
  };

  const exportChart = async (ref, filename) => {
    if (ref.current) {
      try {
        const canvas = await html2canvas(ref.current, {
          backgroundColor: null,
          scrollY: -window.scrollY,
          useCORS: true,
          scale: 2,
        });
        const image = canvas.toDataURL("image/png", 1.0);
        const link = document.createElement('a');
        link.download = filename;
        link.href = image;
        link.click();
        toast({
          title: "Grafik başarıyla indirildi.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Grafik export edilirken hata oluştu:", error);
        toast({
          title: "Grafik indirilemedi.",
          description: "Lütfen tekrar deneyin.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;

    return (
      <g>
        <text
          x={x + width / 2}
          y={y - radius}
          fill="#000"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={10}
        >
          {`%${value}`}
        </text>
      </g>
    );
  };

  const sortedChartData = sortPerformanceData(formattedChartData);
  const sortedPersonnelData = sortPersonnelData(formattedChartData);

  return (
    <>
      <Card mt={4}>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <PageHeader inBox title="Performans" />
          <Flex>
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="sm" mr={2}>
                Sırala: {sortType === "performance" ? "Performans" : "İsim"} ({sortOrder === "desc" ? "Azalan" : "Artan"})
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => handleSortChange("performance")}>
                  Performansa Göre {sortType === "performance" && (sortOrder === "desc" ? "▼" : "▲")}
                </MenuItem>
                <MenuItem onClick={() => handleSortChange("name")}>
                  İsme Göre {sortType === "name" && (sortOrder === "desc" ? "▼" : "▲")}
                </MenuItem>
              </MenuList>
            </Menu>
            <IconButton
              icon={<DownloadIcon />}
              onClick={() => exportChart(chartRef, "performance-chart.png")}
              aria-label="Export chart"
              size="sm"
            />
          </Flex>
        </Flex>
        <Box ref={chartRef} pb={8}>
          <ResponsiveContainer width="100%" aspect={2.6 / 1.0}>
            <BarChart
              data={sortedChartData}
              margin={{ top: 20, left: -20, right: 0, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey={"label"}
                style={{
                  fontSize: "14px",
                }}
              />
              <YAxis
                domain={[0, 100]}
                style={{
                  fontSize: "13px",
                }}
              />
              <Tooltip />
              <Legend />
              <Bar dataKey="performance" name="Genel Performans">
                {sortedChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color || "#4FD1C5"} />
                ))}
                <LabelList dataKey="performance" content={renderCustomizedLabel} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Card>
      {!dashboard && (
        <>
          <Card mt={4}>
            <Flex justifyContent="space-between" alignItems="center" mb={4}>
              <PageHeader inBox title="Personel Performans" />
              <Flex>
                <Menu>
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="sm" mr={2}>
                    Sırala: {sortType === "performance" ? "Performans" : "İsim"} ({sortOrder === "desc" ? "Azalan" : "Artan"})
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => handleSortChange("performance")}>
                      Performansa Göre {sortType === "performance" && (sortOrder === "desc" ? "▼" : "▲")}
                    </MenuItem>
                    <MenuItem onClick={() => handleSortChange("name")}>
                      İsme Göre {sortType === "name" && (sortOrder === "desc" ? "▼" : "▲")}
                    </MenuItem>
                  </MenuList>
                </Menu>
                <IconButton
                  icon={<DownloadIcon />}
                  onClick={() => exportChart(personnelChartRef, "personnel-performance-chart.png")}
                  aria-label="Export chart"
                  size="sm"
                />
              </Flex>
            </Flex>
            <Box ref={personnelChartRef} pb={8}>
              <ResponsiveContainer width="100%" aspect={3.0 / 1.0}>
                <BarChart
                  data={sortedPersonnelData}
                  margin={{ top: 20, left: -20, right: 0, bottom: 20 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey={"label"}
                    style={{
                      fontSize: "14px",
                    }}
                  />
                  <YAxis
                    domain={[0, 100]}
                    style={{
                      fontSize: "13px",
                    }}
                  />
                  <Tooltip />
                  <Legend
                    iconType="square"
                    wrapperStyle={{
                      paddingTop: "20px"
                    }}
                    formatter={(value, entry, index) => <span style={{ color: 'black' }}>{value}</span>}
                  />
                  {data?.personnelTypes.map((per) => (
                    <Bar dataKey={per.name.toString()} key={per.name} name={per.name} fill={per.color || "#4FD1C5"}>
                      <LabelList dataKey={per.name.toString()} content={renderCustomizedLabel} />
                    </Bar>
                  ))}
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Card>
          <Card mt={4}>
            <PageHeader inBox title="İlgili Ziyaret Listesi" />
            <VisitTable visits={data.relatedVisits}  />
          </Card>
        </>
      )}
    </>
  );
}
