import { Button, Input, Stack, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import FormContainer from "../../../components/Form/FormContainer";
import FormFooter from "../../../components/Form/FormFooter";
import StandardFormControl from "../../../components/Form/StandardFormControl";
import { BankSelect } from "../../../components/Form";
import { useApi } from "../../../contexts/ApiContext";
import { regexTrIban } from "../../../util/regex";

export function MSBankInfoForm({ ms }) {
  const toast = useToast();
  const { mysteryShopperApi } = useApi();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      bankName: ms.bankName ?? "",
      IBAN: ms.IBAN ?? "",
    },
  });
  const onSubmit = async (data) => {
    try {
      await mysteryShopperApi.updateOne(ms._id, data);
      toast({
        title: "Başarılı",
        description: "Bilgileriniz güncellendi.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Bir hata oluştu.",
        description: "Lütfen tekrar deneyin.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer>
        <Stack direction={{ base: "column", md: "row" }} spacing="24px">
          {/* <StandardFormControl errors={errors} name="bankName" label="Banka">
            <Input
              id={"bankName"}
              {...register("bankName", { required: "Gerekli alan" })}
            />
          </StandardFormControl> */}
          <BankSelect errors={errors} register={register} />

          <StandardFormControl
            errors={errors}
            name="IBAN"
            label="IBAN Numarası"
          >
            <Input
              id={"IBAN"}
              placeholder={"TR000000000000000000000000"}
              {...register("IBAN", {
                required: "Gerekli alan",
                pattern: { value: regexTrIban, message: "Hatalı IBAN" },
              })}
            />
          </StandardFormControl>
        </Stack>

        <FormFooter>
          <Button colorScheme={"pink"} isLoading={isSubmitting} type="submit">
            Kaydet
          </Button>
        </FormFooter>
      </FormContainer>
    </form>
  );
}
